import React, { useEffect, useState } from "react";

import { graphql } from "gatsby";
import { WebinarBackIcon } from "../assets/icons/webinarBackIcon";
import CustomButton from "../components/CustomButton";
import CustomLink from "../components/CustomLink";
import DemoModal from "../components/DemoModal";
import LogoMarquee from "../components/LogoMarquee";
import HeroCardCopy from "../components/WebinarLp/HeroCard copy";
import RegisterForm from "../components/WebinarLp/RegisterForm";
import WebinarCardSlider from "../components/WebinarLp/WebinarCardSlider";
import Layout from "../components/layout";
import AnnotateFooter from "../components/newAnnotate/AnnotateFooter";
import SEO from "../components/seo";
import Popup from "../partials/Popup";
import {
  formatTimeZoneToDate,
  formatToBSDate,
} from "../utilities/FormatBlogDate";
import { checkFutureTime, pickSlice } from "../utilities/helpers";
import "../styles/page/webinars.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const GATED_WEBINARS = ["/webinar/garbage-in-garbage-out-webinar/"];

const WebinarDetail = ({ data, location }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [gated, setGated] = useState(
    GATED_WEBINARS.includes(location.pathname)
  );
  const uid = data?.prismicWebinarDetail?.uid || "";

  function isUserInAmericaTimezone() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone.startsWith("America/");
  }

  const _data = data?.prismicWebinarDetail?.data || {};

  const webinarCardsData =
    data?.allPrismicWebinarDetail?.nodes?.filter((wb) => wb.uid !== uid) || [];
  const prismicTrustedBrandsLogos = data?.prismicTrustedBrandsLogos?.data || {};
  const [trusted_brands_list] = pickSlice(
    prismicTrustedBrandsLogos,
    "trusted_brands_list"
  );
  const isAmericaTimezone =
    isUserInAmericaTimezone() && _data?.us_start_time && _data?.us_end_time
      ? true
      : false;

  const formId =
    isUserInAmericaTimezone() && _data?.us_form_id?.text
      ? _data?.us_form_id?.text
      : _data?.form_id?.text;
  const [speaker] = pickSlice(_data, "speaker");
  const [tags] = pickSlice(_data, "tags");
  const [expect_info] = pickSlice(_data, "expect_info");

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleRegisterModal = () => {
    setIsRegisterOpen(!isRegisterOpen);
  };

  const webinarCard = {
    otherwebinarHeading: {
      text: "Other webinars that you may like",
    },
  };

  const Speakers = {
    SpeakersHeading: {
      text: "Speakers",
    },
  };

  const VideoIcon = () => (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0938 6.49473L17.3438 8.07812V4.8125C17.3438 4.0541 16.7271 3.4375 15.9688 3.4375H3.25C2.4916 3.4375 1.875 4.0541 1.875 4.8125V17.1875C1.875 17.9459 2.4916 18.5625 3.25 18.5625H15.9688C16.7271 18.5625 17.3438 17.9459 17.3438 17.1875V13.9219L20.0938 15.5053C20.5514 15.7695 21.125 15.4387 21.125 14.9123V7.08984C21.125 6.56133 20.5514 6.23047 20.0938 6.49473ZM15.7969 17.0156H3.42188V4.98438H15.7969V17.0156ZM19.5781 13.4277L17.3438 12.143V9.85918L19.5781 8.57227V13.4277ZM4.96875 7.73438H7.375C7.46953 7.73438 7.54688 7.65703 7.54688 7.5625V6.53125C7.54688 6.43672 7.46953 6.35938 7.375 6.35938H4.96875C4.87422 6.35938 4.79688 6.43672 4.79688 6.53125V7.5625C4.79688 7.65703 4.87422 7.73438 4.96875 7.73438Z"
        fill="#595959"
      />
    </svg>
  );

  const isFuture = checkFutureTime(_data.start_time) || gated;

  useEffect(() => {
    console.log(localStorage.getItem("webinar_form_submitted"));
    if (localStorage.getItem("webinar_form_submitted")) {
      setGated(false);
    }
  }, []);

  return (
    <Layout location={location} theme="newDefault">
      <article>
        <main className="overflow-hidden realtive">
          {isFuture && (
            <section className="fixed bottom-0 z-20 flex w-full px-4 py-2 bg-gray-2800 lg:hidden sm:px-12 sm:py-4">
              <CustomButton
                onClick={handleRegisterModal}
                text="Register now"
                buttonClass="bg-purple-1500 py-2 px-4 text-white"
                isFullWidth
              />
            </section>
          )}

          <article className="relative px-4 pt-32 mx-auto sm:px-12 lg:px-5 max-w-7xl md:pt-44">
            {!isFuture ? (
              <section>
                <section className="relative flex flex-col lg:pb-8 lg:mx-11">
                  <section className="z-20 flex w-ful">
                    <CustomLink
                      to="/webinars"
                      className="flex items-center gap-2"
                    >
                      <span>
                        <WebinarBackIcon />
                      </span>
                      <h4 className="text-lg font-semibold text-gray-600 font-manrope tracking-tighter_1">
                        Back
                      </h4>
                    </CustomLink>
                  </section>

                  <p className="lg:flex justify-center hidden text-center uppercase border-[2px] mx-auto  tracking-widest_1 font-medium rounded-[50px] text-base border-purple-1500 px-5 py-1.5 text-purple-1500">
                    {!_data?.video?.embed_url &&
                    !checkFutureTime(data?.start_time)
                      ? "Coming Soon"
                      : "on demand"}
                  </p>
                </section>
                <section className="flex flex-col gap-6 pb-20 lg:gap-8 lg:mx-11">
                  <p className="flex lg:hidden justify-center text-center uppercase border-[2px] mx-auto  tracking-widest_1 font-medium rounded-[50px] text-xs sm:text-base border-purple-1500 px-5 py-1.5 text-purple-1500">
                    {!_data?.video?.embed_url &&
                    !checkFutureTime(data?.start_time)
                      ? "Coming Soon"
                      : "on demand"}
                  </p>
                  <h1 className="text-purple-1100 text-center max-w-[368px] sm:max-w-[550px] lg:max-w-[760px] tracking-tight sm:tracking-[-1.8px] lg:tracking-tightest_4 mx-auto  sm:text-3.25xl lg:text-4.5xl font-manrope text-2.5xl font-bold sm:font-semibold lg:font-bold">
                    {_data?.page_heading?.text}
                  </h1>
                  {!_data?.hide_date && !isAmericaTimezone && (
                    <div className="flex flex-row items-center justify-center gap-2 mx-auto ">
                      <VideoIcon />
                      <p className="text-base sm:text-xl font-normal text-gray-600 tracking-[-1px]">
                        {formatToBSDate(_data?.start_time)} BST
                      </p>
                    </div>
                  )}
                  {!_data?.hide_date && isAmericaTimezone && (
                    <div className="flex flex-row items-center justify-center gap-2 mx-auto ">
                      <VideoIcon />
                      <p className="text-base sm:text-xl font-normal text-gray-600 tracking-[-1px]">
                        {formatTimeZoneToDate(_data?.us_start_time)} EST
                      </p>
                    </div>
                  )}
                  <section className="flex justify-center">
                    {_data?.video?.embed_url ? (
                      <div
                        className="w-full aspect-video webinar_video"
                        dangerouslySetInnerHTML={{
                          __html: _data?.video?.html,
                        }}
                      />
                    ) : (
                      <div className="w-full text-6xl text-purple-500 ">
                        <GatsbyImage
                          image={getImage(_data?.thumbnail)}
                          alt={"video-thumbnail"}
                          className="h-full w-full rounded-2xl aspect-[16/11  border-none bg-[#F8F8FF]"
                          loading="eager"
                        />

                        {/* <p>Coming Soon</p> */}
                      </div>
                    )}

                    {/* <div dangerouslySetInnerHTML={{
                      __html: _data?.video?.html
                    }}>

                    </div> */}
                    {/* <iframe
                      width="560" height="315"
                      title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                      className="w-[288px] sm:w-[648px] sm:h-[323px] lg:w-[850px] lg:h-[473px] xl:w-[1152px] xl:h-[573px] rounded-2.5xl"
                      src={_data?.video?.embed_url}
                    ></iframe> */}
                  </section>
                  <div className="flex flex-col gap-9 lg:gap-14 lg:flex-row lg:mt-8">
                    <div className="flex flex-col lg:w-1/2 gap-9 lg:gap-16">
                      <div className="flex flex-col gap-2.5 sm:gap-5">
                        <h2 className="text-lg font-semibold text-gray-2700 font-manrope sm:text-2xl tracking-tightest_5">
                          {_data?.page_sub_heading?.text}
                        </h2>
                        <div
                          className="webinar_content"
                          dangerouslySetInnerHTML={{
                            __html: _data?.page_description?.html,
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-5">
                        <p className="text-lg font-semibold text-gray-2700 font-manrope sm:text-2xl tracking-tightest_5 ">
                          {Speakers?.SpeakersHeading?.text}
                        </p>
                        <section className="flex lg:gap-x-6 gap-x-5 max-w-[280px] sm:max-w-[346px] lg:max-w-[unset]">
                          {speaker?.items?.map((speaker, index) => (
                            <div
                              key={index}
                              className="relative flex flex-col items-center text-center sm:w-auto"
                            >
                              <img
                                src={speaker?.speaker_image?.url}
                                height={
                                  speaker?.speaker_image?.dimensions?.height
                                }
                                width={
                                  speaker?.speaker_image?.dimensions?.width
                                }
                                alt={speaker?.speaker_name?.text || "img"}
                                className="w-full h-auto rounded-full lg:w-40 sm:w-36 lg:h-40 sm:h-36"
                              />
                              <h6
                                className={`mt-2 text-base font-semibold sm:text-xl sm:mt-5 tracking-tighter_3 max-w-20 sm:max-w-full ${
                                  index == 0
                                    ? "text-purple-1500"
                                    : "text-green-1300"
                                } `}
                              >
                                {speaker?.speaker_name?.text}
                              </h6>
                              <div className="my-1 text-sm font-normal text-gray-600 tracking-tighter_3 sm:text-base">
                                {speaker?.speaker_designation?.text}
                              </div>
                              <div className="text-sm font-medium sm:text-base tracking-tighter_3 text-gray-2700">
                                {speaker?.speaker_company?.text}
                              </div>
                            </div>
                          ))}
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            ) : (
              <section>
                <section className="relative flex flex-col gap-5 pb-10 sm:pb-11 lg:pb-10 lg:mx-11 sm:gap-12 lg:gap-9">
                  <section className="z-20 flex top-24 lg:top-32 w-ful">
                    <CustomLink
                      to="/webinars"
                      className="flex items-center gap-2"
                    >
                      <span>
                        <WebinarBackIcon />
                      </span>
                      <h4 className="text-lg font-semibold text-gray-600 font-manrope tracking-tighter_1">
                        Back
                      </h4>
                    </CustomLink>
                  </section>
                  <HeroCardCopy
                    isAmericaTimezone={isAmericaTimezone}
                    data={_data}
                    label={
                      checkFutureTime(_data?.start_time)
                        ? `Upcoming webinar`
                        : "Featured webinar"
                    }
                    heading={_data?.page_heading?.text}
                    date={_data?.end_time}
                    time={_data?.start_time}
                    hide_date={_data?.hide_date}
                    description={_data?.page_description?.text}
                    // cta_link={heroCard?.cta_link?.url}
                    speakers={speaker}
                    mainClass="bg_hero"
                  />
                </section>
                <section className="flex flex-col lg:flex-row lg:gap-14 lg:mx-11">
                  <div className="flex flex-col lg:w-1/2 gap-9 lg:gap-16">
                    <div className="flex flex-col gap-2.5 sm:gap-5">
                      <h2 className="text-lg font-semibold text-gray-2700 font-manrope sm:text-2xl tracking-tightest_5">
                        {_data?.page_sub_heading?.text}
                      </h2>
                      <div
                        className="text-base font-normal webinar_content sm:text-lg text-gray-2500 tracking-tighter_3"
                        dangerouslySetInnerHTML={{
                          __html: _data?.page_description?.html,
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col gap-2.5 sm:gap-5">
                      <h2 className="text-lg font-semibold text-gray-2700 font-manrope sm:text-2xl tracking-tightest_5">
                        {expect_info?.primary?.section_heading?.text}
                      </h2>
                      <p
                        className="text-base font-normal sm:text-lg text-gray-2500 tracking-tighter_3"
                        dangerouslySetInnerHTML={{
                          __html:
                            expect_info?.primary?.section_description?.html,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="hidden lg:w-1/2 lg:block">
                    <RegisterForm
                      addRevenueHero={_data?.add_revenuehero}
                      formId={formId}
                      gated={gated}
                      setGated={setGated}
                      formClass={_data?.form_class?.text}
                    />
                  </div>
                </section>
                <section className=" img_grayscale_opacity branding_loop_width py-10 sm:py-[60px] lg:py-20 ">
                  <p className="text-xs font-manrope font-semibold text-center uppercase tracking-widest_1 text-gray-600 mb-[22px] lg:mb-7">
                    {trusted_brands_list?.primary?.label?.text}
                  </p>
                  <LogoMarquee
                    logoArray={trusted_brands_list}
                    logoBackground=""
                  />
                </section>
              </section>
            )}
            <section className=" lg:mx-11 lg:pb-20">
              <h4 className="text-purple-1100 pb-5 font-manrope text-xl text-center lg:text-left sm:text-2.5xl font-semibold lg:font-bold tracking-[-1px] sm:tracking-tightest_6">
                {webinarCard?.otherwebinarHeading?.text}
              </h4>
              <WebinarCardSlider learning_hub_item={webinarCardsData} />
            </section>
            <section className="lg:mx-11">
              <AnnotateFooter
                imageBG={true}
                heading={_data?.cta_heading?.text}
                description={_data?.cta_description?.text}
                openModal={handleModal}
                bgClass="footer_cta_bg_image"
              />
            </section>
          </article>
        </main>
        <DemoModal
          isOpen={isOpen}
          closeModal={handleModal}
          location={location}
        />
        {isFuture && (
          <Popup isOpen={isRegisterOpen} closeModal={handleRegisterModal}>
            <RegisterForm
              addRevenueHero={_data?.add_revenuehero}
              formId={formId}
              formClass={_data?.form_class?.text}
              setGated={setGated}
              gated={gated}
            />
          </Popup>
        )}
      </article>
    </Layout>
  );
};

export default WebinarDetail;

export const query = graphql`
  query PrismicWebinarDetail($uid: String!) {
    prismicWebinarDetail(uid: { eq: $uid }) {
      uid
      data {
        meta_heading {
          text
        }
        meta_description {
          text
        }
        page_heading {
          text
          html
        }
        page_sub_heading {
          text
        }
        page_description {
          text
          html
        }
        video {
          embed_url
          html
        }
        video_time
        featured_webinar
        cta_heading {
          text
        }
        cta_description {
          text
        }
        body {
          ... on PrismicWebinarDetailDataBodySpeaker {
            id
            items {
              speaker_name {
                text
              }
              speaker_image {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 160
                  height: 160
                  sizes: "(min-width: 1040px) 160px, (min-width: 640px) 144px, (min-width: 340px) 129px, calc(-55vw + 296px)"
                )
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              speaker_designation {
                text
              }
              speaker_company {
                text
              }
            }
            slice_type
            primary {
              section_heading {
                text
              }
            }
          }
          ... on PrismicWebinarDetailDataBodyExpectInfo {
            id
            slice_type
            primary {
              section_description {
                text
                html
              }
              section_heading {
                text
                html
              }
            }
          }
        }
        start_time
        hide_date
        thumbnail {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          url
        }
        end_time
        us_start_time
        us_end_time
        us_form_id {
          text
        }
        form_id {
          text
        }
        form_class {
          text
        }
        add_revenuehero
      }
    }
    prismicTrustedBrandsLogos(uid: { eq: "webinar-deatil-page-logos" }) {
      data {
        body {
          ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
            id
            slice_type
            primary {
              label {
                html
                text
              }
            }
            items {
              brand_image {
                alt
                url
              }
            }
          }
        }
      }
    }
    allPrismicWebinarDetail {
      nodes {
        uid
        tags
        data {
          start_time
          video_time
          thumbnail {
            gatsbyImageData(
              width: 272
              height: 210
              sizes: "(min-width: 1280px) 273px, (min-width: 1040px) calc(50vw - 220px), (min-width: 640px) calc(50vw - 60px), calc(100vw - 34px)"
              placeholder: BLURRED
              layout: CONSTRAINED
            )
            url
          }
          page_heading {
            text
          }
          page_description {
            text
            html
          }
          featured_webinar
          end_time
          body {
            ... on PrismicWebinarDetailDataBodySpeaker {
              id
              items {
                speaker_name {
                  text
                }
                speaker_image {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 160
                    height: 160
                    sizes: "(min-width: 1040px) 160px, (min-width: 640px) 144px, (min-width: 340px) 129px, calc(-55vw + 296px)"
                  )
                  url
                  alt
                  dimensions {
                    height
                    width
                  }
                }
                speaker_designation {
                  text
                }
                speaker_company {
                  text
                }
              }
              slice_type
            }
          }
        }
      }
      distinct(field: { tags: SELECT })
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicWebinarDetail?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
