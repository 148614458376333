import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import {
  CalendarIcon,
  FeaturedIcon,
  HeroCardVector,
  TimeIcon,
  UpcomingIcon,
} from "../../assets/icons/webinar";
import {
  formatCombineDate,
  formatCombineUSDate,
} from "../../utilities/FormatBlogDate";
import { checkFutureTime, pickSlice } from "../../utilities/helpers";

const HeroCardCopy = ({
  label,
  heading,
  description,
  hide_date,
  date,
  time,
  speakers,
  mainClass,
  data,
  isAmericaTimezone,
}) => {
  const isFuture = checkFutureTime(time);
  const [_speakers] = pickSlice(speakers, "speaker");

  return (
    <section
      className={`flex justify-between lg:items-start h-full lg:gap-x-16 sm:gap-y-10 gap-y-5 flex-col lg:flex-row ${mainClass} relative overflow-hidden rounded-2.5xl lg:p-10 sm:p-8 py-8 px-3.5 text-white tracking-tighter_3`}
    >
      <div className="absolute z-0 hidden -translate-y-1/2 max-w-none xl:left-72 left-52 top-1/2 lg:block">
        <HeroCardVector />
      </div>
      <div className="absolute bg_hero_elipse lg:right-16 sm:right-32 right-8 lg:top-8 sm:top-14 top-14" />
      <section className="relative flex flex-col justify-center sm:max-w-xl">
        <div className="flex items-center sm:gap-x-2 gap-x-1">
          {isFuture ? <UpcomingIcon /> : <FeaturedIcon />}
          <div className="text-xs font-semibold uppercase tracking-widest_1 opacity-80 font-manrope">
            {label}
          </div>
        </div>

        <h4 className="line-clamp-4 font-semibold sm:text-3.25xl text-2.5xl leading-tight sm:mt-5 my-4 sm:mb-8 font-manrope tracking-tighter_3">
          {heading}
        </h4>
        {!hide_date && !isAmericaTimezone && (
          <div className="flex sm:gap-x-2 gap-x-1.5 items-center">
            {date ? <CalendarIcon /> : <TimeIcon />}
            <div className="flex items-center gap-1 text-sm font-semibold sm:text-lg opacity-70 font-manrope">
              {formatCombineDate(time, date)} <span>BST</span>
            </div>
          </div>
        )}
        {!hide_date && isAmericaTimezone && (
          <div className="flex sm:gap-x-2 gap-x-1.5 items-center">
            {date ? <CalendarIcon /> : <TimeIcon />}
            <div className="flex items-center gap-1 text-sm font-semibold sm:text-lg opacity-70 font-manrope">
              {formatCombineUSDate(data?.us_start_time, data?.us_end_time)}
              <span>EST</span>
            </div>
          </div>
        )}
      </section>

      <section className="flex lg:gap-6 gap-[18px] flex-shrink-0 max-w-[280px] sm:max-w-[346px] lg:max-w-[unset]">
        {speakers?.items?.map((speaker, index) => {
          const image = getImage(speaker?.speaker_image);

          return (
            <div
              key={index}
              className="relative flex flex-col items-center text-center max-w-[131px] sm:max-w-[unset]"
            >
              <GatsbyImage
                image={image}
                alt={speaker?.speaker_name?.text || "img"}
                className="rounded-full lg:w-40 sm:w-36 w-[131px] lg:h-40 sm:h-36 h-[131px]"
                loading="eager"
              />

              <div className="mt-2 text-base font-semibold sm:text-xl sm:mt-5 max-w-20 sm:max-w-full">
                {speaker?.speaker_name?.text}
              </div>
              <div className="my-1 text-sm sm:text-base text-gray-2200">
                {speaker?.speaker_designation?.text}
              </div>
              <div className="text-sm font-medium sm:text-base">
                {speaker?.speaker_company?.text}
              </div>
            </div>
          );
        })}
      </section>
    </section>
  );
};

export default HeroCardCopy;
